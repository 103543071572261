import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`

  *{
  box-sizing: border-box;
      padding: 0px;
      margin: 0px;

      font-family: 'Montserrat', sans-serif;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #1d1d1d;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #444;
  }

  .aligned{
    align-items: center;
  }

  .flex{
    display: flex;
  }

  .fore{
    background-color: #444;
    padding: 5px;
  }

 .round{
    border-radius: 5px;
  }

  .full-height{
    flex:1;
  }

  .full-width{
    width: 100%;
  }

  .spaced{
    justify-content: space-between;
  }

  .centered{
    align-items: center;
    justify-content: center;
  }


  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn .2s ease-in-out;
  }
    
`

export default GlobalStyles
