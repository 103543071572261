import styled from "styled-components"

export const Background = styled.div`
    display: flex;

    align-items: center;

    height: 30px;
    padding-inline: 10px;
    border-radius: 5px;

    flex-direction: row;

    border: 1px solid #29ff7e;

    background-color: #222;
    color: white;

    :focus {
        border-color: #29ff7e;
    }

    ::placeholder {
        font-style: italic;
        opacity: 0.8;
    }

    p {
        margin-right: 10px;
        border-color: #29ff7e;
        color: #29ff7e;

        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /* Estilo para remover a cor de fundo do autofill */
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #333 inset; /* Defina a cor desejada (neste caso, branco) */
        -webkit-text-fill-color: white;
        background-color: #333 !important; /* Também definido como a cor desejada (neste caso, branco) */
        height: 20px;
    }

    input,
    textarea {
        background-color: transparent;
        border: none;
        color: white;
        width: 100%;
        height: 100%;
        outline: none;
        font-size: 12px;
    }

    textarea {
        display: flex;
        resize: none;
        padding-block: 10px;
    }
`
