import React, { useEffect, useState } from "react"
import { TextArea } from "../../../components/CustomInput"
import { Column } from "../../Dashboard/styles"
import { Lang } from ".."

// import { Container } from './styles';

interface MultiAreaProps {
    desc: {
        pt: string
        en: string
        es: string
    }
    language: Lang
    onChange: (lang: Lang, value: string) => void
}
/* */

const MultiTextAreaLanguage: React.FC<MultiAreaProps> = (props) => {
    const [language, setLanguage] = useState<Lang>("pt")

    const [ptbr, setPtbr] = useState<string>(props.desc.pt)
    const [enus, setEnus] = useState<string>(props.desc.en)
    const [eseu, setEseu] = useState<string>(props.desc.es)

    useEffect(() => {
        const desc = { ...props.desc }
        setPtbr(desc.pt)
        setEnus(desc.en)
        setEseu(desc.es)
    }, [props.desc])

    useEffect(() => {
        setLanguage(props.language)
    }, [props.language])

    function GetText() {
        switch (language) {
            default:
                return ptbr
            case "en":
                return enus
            case "es":
                return eseu
        }
    }

    function ChangeDescription(v: string) {
        switch (language) {
            default:
                setPtbr(v)
                break
            case "en":
                setEnus(v)
                break
            case "es":
                setEseu(v)
                break
        }
    }

    function SubmitChanges() {
        props.onChange(language, GetText())
    }

    return (
        <Column className="flex full-height">
            {language === props.language && (
                <TextArea
                    style={{ height: "100%", width: "100%" }}
                    placeholder={"Descrição " + language}
                    defaultValue={GetText()}
                    onBlur={() => SubmitChanges()}
                    onChangeText={ChangeDescription}
                />
            )}
        </Column>
    )
}

export default MultiTextAreaLanguage
