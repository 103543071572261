import React, { useEffect, useState } from "react"
import api from "../../api"
import { Row } from "../Dashboard/styles"
import { AddButton, CategoryItem, List } from "./styles"
import { MdCheckCircle, MdNewLabel, MdRemoveCircle } from "react-icons/md"

// import { Container } from './styles';

interface Category {
    id: number
    label: string
}

interface CategoriesProps {
    default: number[]
    onSelect: (arr: number[]) => void
}

const Categories: React.FC<CategoriesProps> = (props) => {
    const [categories, setCategories] = useState<Category[]>([])
    const [selected, setSelected] = useState<number[]>(
        props.default as number[]
    )

    useEffect(() => {
        loadCategories()
    }, [])

    async function loadCategories() {
        await api
            .get("/categories/list")
            .then((res) => {
                setCategories(res.data as Category[])
            })
            .catch((err) => alert(err))
    }

    async function createCategory() {
        await api
            .get("/categories/create")
            .then((res) => {
                const arr = [...categories]
                arr.unshift(res.data[0] as Category)
                setCategories(arr)
            })
            .catch((err) => alert(err))
    }

    async function Delete(id: number) {
        await api
            .post("/categories/delete", { id: id })
            .then(() => {
                const arr = [...categories]
                const index = categories.findIndex((e) => e.id === id)
                arr.splice(index, 1)
                setCategories(arr)
            })
            .catch((err) => {
                alert(err)
            })
    }

    async function rename(id: number, label: string) {
        await api
            .post("/categories/rename", { id: id, label: label })
            .then((res) => {
                loadCategories()
            })
            .catch((err) => alert(err))
    }

    function toggle(cat: Category) {
        if (includes(cat)) {
            const s = [...selected]
            s.splice(s.indexOf(cat.id), 1)
            setSelected(s)
            props.onSelect(s)
        } else {
            const s = [...selected]
            s.push(cat.id)
            setSelected(s)
            props.onSelect(s)
        }
    }

    function includes(cat: Category) {
        return selected.includes(cat.id)
    }

    return (
        <div style={{ flex: 1 }}>
            <Row style={{ marginBottom: 10 }} className={"spaced aligned"}>
                <h6>CATEGORIAS</h6>
                <AddButton onClick={createCategory}>
                    <MdNewLabel size={20} />
                </AddButton>
            </Row>

            <List>
                {categories.map((element) => {
                    return (
                        <CategoryItem key={element.id}>
                            <button
                                className={"flex centered"}
                                onClick={() => toggle(element)}
                            >
                                <MdCheckCircle
                                    size={20}
                                    color={
                                        !includes(element) ? "white" : "#29ff7e"
                                    }
                                />
                            </button>

                            <input
                                defaultValue={element.label}
                                onBlur={(e) =>
                                    rename(element.id, e.target.value)
                                }
                            />
                            <button
                                className={"flex centered round"}
                                onClick={() => Delete(element.id)}
                            >
                                <MdRemoveCircle size={12} color="red" />
                            </button>
                        </CategoryItem>
                    )
                })}
            </List>
        </div>
    )
}

export default Categories
