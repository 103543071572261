import React, { useEffect, useState } from "react"
import Input from "../../components/CustomInput"
import {
    Row,
    Column,
    Cover,
    PrintsContainer,
    Delete,
    CreateButton,
    VerticalContainer,
    Idioma,
    Print,
    UploadInput,
    UploadObject,
    PrintUpload,
    Scroll,
} from "../Dashboard/styles"
import { Game } from "../../interfaces"
import { GetCached, RemoveCache, SetCache } from "./cache"
import api from "../../api"
import { MdDeleteForever, MdUpload } from "react-icons/md"
import Categories from "../Categories"
import MultiTextAreaLanguage from "./MultiTextAreaLanguage"
import { UploadCover, UploadPrints } from "../../functions"
import ToggleButton from "../../components/ToggleButton"
import AdvancedNotification from "./AdvancedNotification"

// import { Container } from './styles';

export type Lang = "pt" | "en" | "es"

interface EditorProps {
    game: Game
    onRemove: (game: Game) => void
    onSave: (game: Game) => void
    onOpenModal: (open: boolean) => void
}

const GameEditor: React.FC<EditorProps> = (props) => {
    let initialGame = new Game(props.game)

    const [game, setGame] = useState<Game>(GetCached(initialGame))

    const [notify, setNotify] = useState(false)

    const [language, setLanguage] = useState<Lang>("pt")

    useEffect(() => {
        setLanguage("pt")
        setGame(GetCached(initialGame))
    }, [props.game])

    useEffect(() => {}, [game])

    function Update(g?: Game) {
        setGame(g ? g : game)
        SetCache(g ? g : game)
    }

    function _handleTitle(e: React.ChangeEvent<HTMLInputElement>) {
        game.title = e.target.value
        Update()
    }

    function _handleDescription(lang: Lang, value: string) {
        game.setDescription(lang, value)
        Update()
    }

    function _handleLinkDownload(e: React.ChangeEvent<HTMLInputElement>) {
        game.download_url = e.target.value
        Update()
    }

    function _handleLinkPremium(e: React.ChangeEvent<HTMLInputElement>) {
        game.premium_url = e.target.value
        Update()
    }

    function _handleYear(e: React.ChangeEvent<HTMLInputElement>) {
        game.year = e.target.value
        Update()
    }

    function _handleLanguage(e: React.ChangeEvent<HTMLInputElement>) {
        game.language = e.target.value
        Update()
    }

    function _handleSize(e: React.ChangeEvent<HTMLInputElement>) {
        game.size = e.target.value
        Update()
    }

    function _handleCategory(e: number[]) {
        game.category = e as number[]
        Update()
    }

    function _handlePublished(e: React.ChangeEvent<HTMLSelectElement>) {
        game.visibility = parseInt(e.target.value)
        Update()
    }

    async function RemoveGame() {
        await api
            .post("/games/delete", { id: game.id })
            .then(() => {
                props.onRemove(game)
            })
            .catch((err) => {
                alert(err)
            })
    }

    async function Upload() {
        await api
            .post("/games/save", { game: game, notify })
            .then((res) => {
                props.onSave(res.data[0] as Game)
                RemoveCache(game)
            })
            .catch((err) => {
                alert(err)
            })
    }

    const onCoverChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const f = event.target.files ? event.target.files[0] : null

        if (event && f) {
            await UploadCover(game.id, f)
                .then((res) => {
                    const g = { ...game } as Game
                    g.cover = res
                    Update(g)
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    function Remove(name: string) {
        const g = { ...game }
        g.prints.splice(g.prints.indexOf(name), 1)
        setGame(new Game(g as Game))
    }

    const onPrintsChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const f = event.target.files ? event.target.files : null

        if (event && f) {
            await UploadPrints(game.id, f)
                .then((res) => {
                    const g = { ...game } as Game
                    res.forEach((element) => {
                        if (!g.prints.includes(element)) g.prints.push(element)
                    })
                    Update(g)
                })
                .catch((err) => {
                    alert(err)
                })
        }
    }

    const GamePrints = () => {
        if (typeof game.prints === "string")
            game.prints = JSON.parse(game.prints)

        if (typeof game.prints !== "string" && game.prints.length > 0) {
            return (
                <>
                    {game.prints.map((element, index) => {
                        return (
                            <div style={{ position: "relative" }}>
                                <button
                                    style={{
                                        all: "unset",
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: 12,
                                        right: 20,
                                    }}
                                    onClick={() => Remove(element)}
                                >
                                    <MdDeleteForever color="white" />
                                </button>
                                <Print key={element + index} src={element} />
                            </div>
                        )
                    })}
                </>
            )
        } else {
            return <></>
        }
    }

    return (
        <>
            {game.id === props.game.id && (
                <Row
                    className="fade-in"
                    style={{
                        flex: 1,
                        width: 1000,
                    }}
                >
                    <VerticalContainer>
                        <Row>
                            <Column style={{ width: "50%", marginRight: 20 }}>
                                <Cover>
                                    <img src={game.cover} alt="logo" />

                                    <UploadObject className={"flex centered"}>
                                        {game.cover === "" && (
                                            <MdUpload size={24} color="white" />
                                        )}
                                        <UploadInput
                                            type="file"
                                            onChange={onCoverChange}
                                        />
                                    </UploadObject>
                                </Cover>

                                <Input
                                    placeholder="Idioma"
                                    right
                                    onChange={_handleLanguage}
                                    defaultValue={game.language}
                                />
                                <span />

                                <Input
                                    placeholder="Ano"
                                    right
                                    onChange={_handleYear}
                                    defaultValue={game.year}
                                />
                                <span />
                                <Input
                                    placeholder="Tamanho"
                                    right
                                    onChange={_handleSize}
                                    defaultValue={game.size}
                                />
                            </Column>

                            <Column style={{ width: "100%" }}>
                                <Input
                                    placeholder="Titulo"
                                    onChange={_handleTitle}
                                    defaultValue={game.title}
                                />
                                <span />

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        flexBasis: 0,
                                        flexShrink: 0,
                                        flexGrow: 0,
                                    }}
                                >
                                    <Idioma
                                        selected={language === "pt"}
                                        onClick={() => setLanguage("pt")}
                                    >
                                        <h6>PORTUGUÊS</h6>
                                    </Idioma>
                                    <Idioma
                                        selected={language === "en"}
                                        onClick={() => setLanguage("en")}
                                    >
                                        <h6>INGLÊS</h6>
                                    </Idioma>
                                    <Idioma
                                        selected={language === "es"}
                                        onClick={() => setLanguage("es")}
                                    >
                                        <h6>ESPANHOL</h6>
                                    </Idioma>
                                </div>

                                <span />

                                <MultiTextAreaLanguage
                                    onChange={_handleDescription}
                                    language={language}
                                    desc={{
                                        pt: game.description,
                                        en: game.description_en,
                                        es: game.description_es,
                                    }}
                                />
                            </Column>
                        </Row>

                        <span />
                        <Input
                            className="full-width"
                            width={20}
                            placeholder="Link Download"
                            onChange={_handleLinkDownload}
                            defaultValue={game.download_url}
                        />
                        <span />
                        <Input
                            className="full-width"
                            width={20}
                            placeholder="Link Premium"
                            onChange={_handleLinkPremium}
                            defaultValue={game.premium_url}
                        />
                        <PrintsContainer>
                            <Scroll>
                                <GamePrints />
                                <PrintUpload>
                                    <UploadObject className={"flex centered"}>
                                        <MdUpload size={48} color="white" />
                                        <UploadInput
                                            type="file"
                                            onChange={onPrintsChange}
                                        />
                                    </UploadObject>
                                </PrintUpload>
                            </Scroll>
                        </PrintsContainer>
                    </VerticalContainer>
                    <span />
                    <VerticalContainer style={{ maxWidth: 200 }}>
                        <Column className="full-height">
                            <Column className={"full-height fore round"}>
                                <Categories
                                    default={game.category}
                                    onSelect={_handleCategory}
                                />
                            </Column>
                        </Column>
                        <span />
                        <Column>
                            <Row>
                                <ToggleButton
                                    onToggle={setNotify}
                                    active={notify}
                                />
                            </Row>
                            {game && (
                                <AdvancedNotification
                                    game={game}
                                    onEnable={props.onOpenModal}
                                />
                            )}
                            <span />
                            <select
                                style={{
                                    border: "1px solid #29ff7e",
                                    borderRadius: 5,
                                    fontWeight: "bold",
                                }}
                                defaultValue={game.visibility}
                                onChange={_handlePublished}
                            >
                                <option value={"0"}>Não Publicado</option>
                                <option value={"1"}>Publicado</option>
                            </select>
                            <span />
                            <Row className="aligned">
                                <Delete
                                    className="flex centered"
                                    onClick={RemoveGame}
                                >
                                    <MdDeleteForever size={25} />
                                </Delete>
                                <span />
                                <CreateButton onClick={Upload}>
                                    <p style={{ position: "relative" }}>
                                        Salvar
                                    </p>
                                </CreateButton>
                            </Row>
                        </Column>
                    </VerticalContainer>
                </Row>
            )}
        </>
    )
}

export default GameEditor
