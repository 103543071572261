import styled, { keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    // Propriedades da animação
    animation: ${fadeIn} 0.1s ease-in-out;
`

const ModalContent = styled.div`
    background-color: #222;
    border: 1px solid #29ff7e;

    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px #29ff7e;
`

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: white;
    text-transform: uppercase;
`

const ModalTitle = styled.h2`
    flex: 1;
    margin: 0;
`

const ModalBody = styled.div`
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    div {
        display: flex;
    }
`

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        background-color: #29ff7e;
        border: none;
        padding: 8px;
        margin-left: 10px;
        border-radius: 5px;

        cursor: pointer;

        transition: all 0.2s;

        :hover {
            border-radius: 25px;
            background-color: #fff;
        }
    }
`

export {
    ModalContainer,
    ModalContent,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter,
}
