import React, {
    DetailedHTMLProps,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
} from "react"
import { Background } from "./styles"

// import { Container } from './styles';

type customInput = DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
>

interface customProps {
    onChangeText?: (text: string) => void
    right?: boolean
}

const Input: React.FC<customInput & customProps> = (props) => {
    return (
        <Background style={props.style} className={props.className}>
            <p>{props.placeholder}:</p>
            <input
                {...props}
                placeholder=""
                style={{ textAlign: props.right ? "right" : "unset" }}
            ></input>
        </Background>
    )
}

type textArea = DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>

export const TextArea: React.FC<textArea & customProps> = (props) => {
    function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (props.onChange) props.onChange(e)
        if (props.onChangeText) props.onChangeText(e.target.value)
    }

    return (
        <Background style={props.style}>
            <textarea {...props} onChange={onChange}></textarea>
        </Background>
    )
}

export default Input
