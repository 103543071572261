import React from "react"
import ReactDOM from "react-dom/client"

import Dashboard from "./Screens/Dashboard"
import GlobalStyles from "./globalstyles"
import Login from "./Screens/Login"
import { AuthContextComponent, useAuth } from "./contexts/AuthContext"

const AuthApp: React.FC = () => {
    const auth = useAuth()

    if (auth.logged) {
        return (
            <div className="App">
                <Dashboard />
                <GlobalStyles />
            </div>
        )
    } else {
        return <Login />
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <AuthContextComponent>
            <AuthApp />
        </AuthContextComponent>
    </React.StrictMode>
)
