import React, { useEffect } from "react"
import { Container, Panel } from "./styles"
import { useAuth } from "../../contexts/AuthContext"
import { MdChevronRight } from "react-icons/md"
import Input from "../../components/CustomInput"

// import { Container } from './styles';

const Login: React.FC = () => {
    const [login, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")

    const auth = useAuth()

    useEffect(() => {
        const email = localStorage.getItem("email")
        if (email != undefined) setUsername(email)
    }, [])

    function Login() {
        auth.handleLogin(login, password)
    }

    function handleUsername(e: React.ChangeEvent<HTMLInputElement>) {
        setUsername(e.target.value)
        localStorage.setItem("email", e.target.value)
    }

    function handlePassword(e: React.ChangeEvent<HTMLInputElement>) {
        setPassword(e.target.value)
    }

    return (
        <Container>
            <Panel>
                <img src={require("../../assets/igames-icon.png")} />
                <Input
                    style={{ width: "80%" }}
                    onChange={handleUsername}
                    placeholder="Usuário"
                    defaultValue={login}
                    right
                />

                <span style={{ margin: 3 }} />
                <Input
                    style={{ width: "80%" }}
                    onChange={handlePassword}
                    placeholder="Senha"
                    type="password"
                    right
                />

                <span style={{ margin: 10 }} />
                <button onClick={Login}>
                    Entrar <MdChevronRight />
                </button>
            </Panel>
        </Container>
    )
}

export default Login
