import styled from "styled-components"

export const List = styled.ul`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    flex-basis: 1;
    overflow-y: auto;

    height: 100%;
`

export const CategoryItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    margin-bottom: 5px;

    align-items: center;

    border-radius: 5px;

    button {
        all: unset;
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0px;
        margin: 0px;

        cursor: pointer;
    }

    input {
        all: unset;
        width: 100%;
        font-size: 12px;

        text-transform: uppercase;
        padding: 5px;
    }
`

export const AddButton = styled.button`
    display: flex;

    outline: none;
    border-radius: 5px;
    border: none;
    background-color: white;
    height: 20px;
    aspect-ratio: 1;
    display: flex;
    background-color: white;

    justify-content: center;
    align-items: center;
`
