import React, { useRef, useState } from "react"
import {
    ModalContainer,
    ModalContent,
    ModalHeader,
    ModalTitle,
    ModalBody,
    ModalFooter,
} from "./styles"
import ReactDOM from "react-dom"
import { useOnOutClick } from "../hooks"

interface ModalProps {
    title: string
    body: React.ReactNode
    footer?: React.ReactNode
    onClose?: () => void
}

const useModal = (unBlur?: () => void) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const ref = useRef<HTMLDivElement>(null)
    useOnOutClick(ref, () => {
        closeModal()
        unBlur && unBlur()
    })

    const Modal = (props: ModalProps) =>
        ReactDOM.createPortal(
            isOpen ? (
                <ModalContainer>
                    <ModalContent ref={ref}>
                        <ModalHeader>
                            <ModalTitle>{props.title}</ModalTitle>
                        </ModalHeader>
                        <ModalBody>{props.body}</ModalBody>
                        {props.footer && (
                            <ModalFooter>{props.footer}</ModalFooter>
                        )}
                    </ModalContent>
                </ModalContainer>
            ) : (
                <></>
            ),
            document.body
        )

    return { openModal, closeModal, Modal }
}

export default useModal
