import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vw;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
`

export const Panel = styled.div`
    display: flex;
    width: 300px;
    height: 200px;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;

    font-size: 24px;

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 15px;

        border-radius: 5px;
    }

    button {
        display: flex;
        border-radius: 4px;
        background-color: #29ff7e;
        border: none;
        padding: 5px;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;

        cursor: pointer;
    }
`
