import { Game } from "../../interfaces"

const tag = (g: Game) => {
    return "game_" + g.id
}

export function GetCached(game: Game & { cached?: boolean }) {
    const cached = localStorage.getItem(tag(game))

    game.cached = cached !== undefined

    if (cached) {
        game = new Game(JSON.parse(cached) as Game)
        game.cached = true
        return game
    }

    return new Game(game)
}

export function SetCache(game: Game) {
    localStorage.setItem(tag(game), JSON.stringify(game))
}

export function IsCached(game: Game) {
    return localStorage.getItem(tag(game)) !== null
}

export function RemoveCache(game: Game) {
    localStorage.removeItem(tag(game))
}
