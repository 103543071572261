import React, { useEffect, useState } from "react"
import { Game } from "../../../interfaces"
import { NotificationProps, NotifyAPI } from "../../../assets/functions"
import Input from "../../../components/CustomInput"
import { ConfirmationModalFooter } from "../../../components/modal/example"
import useModal from "../../../components/modal/useModal"
import { Row, NotifyButton } from "../../Dashboard/styles"
import ToggleButton from "../../../components/ToggleButton"

// import { Container } from './styles';

const AdvancedNotification: React.FC<{
    game: Game
    onEnable: (value: boolean) => void
}> = ({ game, onEnable }) => {
    const [not, setNot] = useState<NotificationProps>({
        title: "",
        subtitle: "",
        deep_link: "",
        image: "",
    })

    const { openModal, closeModal, Modal } = useModal(() => onEnable(false))

    const [debug, setDebug] = useState(true)

    useEffect(() => {
        setNot({
            title: game.title,
            subtitle: "NOVO JOGO!",
            deep_link: "igpsp://game/" + game.id,
            image: game.cover,
        })

        console.log("re-rendered advanced notification")
    }, [game])

    function handleCloseModal() {
        closeModal()
        onEnable(false)
    }

    function handleOpenModal() {
        openModal()
        onEnable(true)
    }

    function handleNotform(e: React.ChangeEvent<HTMLInputElement>) {
        setNot({
            ...not,
            [e.target.name]: e.target.value,
        })
    }

    function Submit() {
        NotifyAPI({ ...not, debug })
            .then((res) => {
                console.log(JSON.stringify(res.data))
                alert(JSON.stringify(res.data))
            })
            .catch((err) => {
                alert("Não foi possível notificar!\n" + err)
            })
    }

    return (
        <Row style={{ flex: 1, flexDirection: "column" }}>
            <NotifyButton onClick={handleOpenModal}>NOTIFICAR</NotifyButton>
            <Modal
                title="Notificar"
                body={
                    <div
                        style={{
                            minWidth: 600,
                            flexDirection: "column",
                        }}
                    >
                        <Input
                            name="title"
                            onBlur={handleNotform}
                            placeholder="Titulo"
                            defaultValue={not.title}
                        />
                        <br />
                        <Input
                            name="subtitle"
                            onBlur={handleNotform}
                            placeholder="Subtitulo"
                            defaultValue={not.subtitle}
                        />
                        <br />
                        <Input
                            name="deep_link"
                            onBlur={handleNotform}
                            placeholder="Deep Link"
                            defaultValue={not.deep_link}
                        />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    height: 50,
                                    aspectRatio: 0.8,
                                    borderRadius: 8,
                                }}
                                alt={game.cover}
                                src={not.image}
                            />
                            <Input
                                name="image"
                                onBlur={handleNotform}
                                style={{
                                    marginInline: 10,
                                    width: "100%",
                                    height: 50,
                                }}
                                placeholder="Imagem"
                                defaultValue={not.image}
                            />
                            <ToggleButton
                                title="NOTIFICAÇÃO DE TESTE"
                                onToggle={setDebug}
                                active={debug}
                            />
                        </div>
                    </div>
                }
                footer={
                    <ConfirmationModalFooter
                        confirm={() => Submit()}
                        cancel={handleCloseModal}
                    />
                }
                onClose={handleCloseModal}
            />
        </Row>
    )
}

export default AdvancedNotification
