import React, { useEffect, useState } from "react"
import { MdCheckCircle, MdCircle } from "react-icons/md"

const ToggleButton: React.FC<{
    title?: string
    active: boolean
    onToggle(b: boolean): void
}> = ({ active, onToggle, title }) => {
    const handleToggle = () => {
        onToggle(!active)
    }

    return (
        <button
            style={{
                border: "1px solid #29ff7e",
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                backgroundColor: "#111",
                padding: 8,
                borderRadius: 5,
                cursor: "pointer",
                alignItems: "center",
            }}
            onClick={handleToggle}
        >
            {active ? (
                <MdCheckCircle color={"#29ff7e"} size={24} />
            ) : (
                <MdCircle color={"#29ff7e"} size={24} />
            )}
            <p style={{ fontSize: 12, color: "white" }}>
                {title ? title : "NOTIFICAR(VELHO)"}
            </p>
        </button>
    )
}

export default ToggleButton
