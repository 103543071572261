import api from "../api"

const GetURL = (id: string, filename: string) => {
    return `https://img.igamesbr.com/${id}/${filename}`
}

const GetURLs = (id: string, filenames: string[]) => {
    const arr: string[] = []

    for (let i = 0; i < filenames.length; i++)
        arr.push(GetURL(id, filenames[i]))

    return arr
}

const config = {
    headers: {
        "content-type": "multipart/form-data",
    },
}

export async function UploadCover(gameId: string, file: File) {
    var form = new FormData()

    form.append("id", gameId)
    form.append(`image`, file, file.name)

    return api
        .post("/upload/cover/", form, config)
        .then((res) => {
            return GetURL(gameId, res.data)
        })
        .catch((err) => {
            alert(err)
            return ""
        })
}

export async function UploadPrints(gameId: string, files: FileList) {
    var form = new FormData()

    form.append("id", gameId)

    for (let i = 0; i < files.length; i++) {
        form.append(`images`, files[i], files[i].name)
    }

    return api
        .post("/upload/prints/", form, config)
        .then((res) => {
            return GetURLs(gameId, res.data)
        })
        .catch((err) => {
            alert(err)
            return [] as string[]
        })
}
