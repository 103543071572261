import React, { CSSProperties, ReactNode } from "react"
import useModal from "./useModal"
import { ModalBody, ModalFooter } from "./styles"

export const ModalContent: React.FC<{
    children?: ReactNode
    style?: CSSProperties
}> = ({ children, style }) => <ModalBody style={style}>{children}</ModalBody>

export const ConfirmationModalFooter: React.FC<{
    confirm?: () => void
    cancel: () => void
}> = (props) => (
    <ModalFooter>
        <button onClick={props.cancel}>Cancelar</button>
        <button onClick={props.confirm}>Confirmar</button>
    </ModalFooter>
)

const App: React.FC = () => {
    const { openModal, closeModal, Modal } = useModal()

    return (
        <div>
            <button onClick={openModal}>Open Modal</button>
            <Modal
                title="Confirmation"
                body={<ModalContent />}
                footer={<ConfirmationModalFooter cancel={closeModal} />}
                onClose={closeModal}
            />
        </div>
    )
}

export default App
