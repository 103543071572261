import React, { createContext, useContext, useState } from "react"
import api from "../../api"
import md5 from "md5"
import { GetDataBool, SetData } from "./cache"

interface LoginProps {
    logged: boolean
    handleLogin: (login: string, password: string) => void
}

const AuthContext = createContext<LoginProps | undefined>(undefined)

export const AuthContextComponent: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [logged, setLogged] = useState(GetDataBool("lginfo"))

    const handleLogin = (login: string, password: string) => {
        api.post("admin/login", { login, password: md5(password) })
            .then((res) => {
                if (res.status === 201) {
                    setLogged(true)
                    SetData("lginfo", true)
                }
            })
            .catch((err) => {
                alert(err)
            })
    }

    return (
        <AuthContext.Provider value={{ logged, handleLogin }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error("useSearch must be used within a SearchProvider")
    }
    return context
}

export default AuthContext
