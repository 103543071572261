export class Game {
    id: string = ""
    title: string = "Novo Game"
    description = ""
    description_en = ""
    description_es = ""
    language: string | undefined = undefined
    size: string | undefined = undefined
    year: string | undefined = undefined
    download_url: string = "https://download.tgamesandroid.com/IgamesPSP/"
    premium_url: string = "https://premium.tgamesandroid.com/IgamesPSP/"
    category: number[] = []
    views: number = 0
    visibility: number = 0
    createdat: Date = new Date()
    cover: string = ""
    prints: string[] = []
    cached = false

    constructor(g: Game | null) {
        if (g === null) return

        this.id = g.id
        this.title = g.title
        this.description = g.description
        this.description_en = g.description_en
        this.description_es = g.description_es
        this.language = g.language
        this.size = g.size
        this.year = g.year
        this.download_url = g.download_url
        this.premium_url = g.premium_url
        this.category =
            typeof g.category === "string"
                ? JSON.parse(g.category)
                : (g.category as number[])
        this.views = g.views
        this.visibility = g.visibility
        this.createdat = g.createdat
        this.cover = g.cover
        this.prints =
            typeof g.prints === "string"
                ? JSON.parse(g.prints)
                : (g.prints as string[])
    }

    setDescription(lang: "pt" | "en" | "es", value: string) {
        switch (lang) {
            default:
                this.description = value
                break
            case "en":
                this.description_en = value
                break
            case "es":
                this.description_es = value
                break
        }
    }
}
