import React, { useEffect, useState } from "react"
import { Background, Column, Foreground, Item, List, Row } from "./styles"
import api from "../../api"

import {
    MdAdd,
    MdCheckCircle,
    MdRemoveCircle,
    MdVisibility,
} from "react-icons/md"
import { Game } from "../../interfaces"
import GameEditor from "../GameEditor"
import { IsCached } from "../GameEditor/cache"
import Input from "../../components/CustomInput"
import { NotificationProps } from "../../assets/functions"

// import { Container } from './styles';

const Dashboard: React.FC = () => {
    const [games, setGames] = useState<Game[]>([])
    const [selected, setSelected] = useState<Game | null>(null)
    const [filter, setFilter] = useState("")
    const [blur, setBlur] = useState(false)

    const filtered = games.filter((e) =>
        e.title.toUpperCase().includes(filter.toUpperCase())
    )

    useEffect(() => {
        loadGames()
    }, [])

    async function loadGames() {
        await api.get("/games/list").then((res) => {
            setGames(res.data as Game[])
        })
    }

    async function EditGame(game: Game) {
        const index = games.findIndex((g) => g.id === game.id)

        const arr = [...games]
        arr[index] = game

        setGames(arr)
    }

    async function RemoveGame(game: Game) {
        const index = games.findIndex((g) => g.id === game.id)

        const arr = [...games]
        arr.splice(index, 1)

        setGames(arr)

        setSelected(null)
    }

    async function CreateGame() {
        await api
            .get("/games/create")
            .then((res) => {
                const arr = [...games]
                arr.unshift(res.data[0] as Game)
                setGames(arr)
                setSelected(games[0])
            })
            .catch((err) => alert(err))
    }

    function Select(game: Game) {
        setSelected(selected && game.id === selected.id ? null : game)
    }

    return (
        <Background blur={blur}>
            <div>
                <div
                    style={{
                        display: "flex",
                        height: 800,

                        flexDirection: "row",
                        alignItems: "center",

                        justifyContent: "space-between",
                    }}
                >
                    <Foreground width="100%" style={{ alignItems: "center" }}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <h4>JOGOS CADASTRADOS</h4>

                            <button
                                style={{
                                    display: "flex",
                                    width: 100,
                                    borderRadius: 25,
                                    backgroundColor: "#29ff7e",
                                    padding: 7,
                                    fontWeight: "bold",
                                    border: "none",
                                    cursor: "pointer",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flexDirection: "row",
                                }}
                                onClick={CreateGame}
                            >
                                <MdAdd size={20} /> <p>CRIAR</p>
                            </button>
                        </div>
                        <Input
                            style={{ width: "100%", marginTop: 5 }}
                            placeholder="Pesquisar"
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <br />
                        <List>
                            {filtered.map((element, index) => {
                                return (
                                    <Item
                                        key={index}
                                        onClick={() => Select(element)}
                                        style={{
                                            opacity:
                                                element.id === selected?.id
                                                    ? 0.5
                                                    : 1,
                                        }}
                                    >
                                        <p>
                                            {element["title"].toUpperCase()}
                                            {IsCached(element) && " - Rascunho"}
                                        </p>
                                        <Row style={{ alignItems: "center" }}>
                                            <Column>
                                                <h4>
                                                    {new Date(
                                                        element.createdat
                                                    ).toLocaleDateString()}
                                                </h4>
                                                <Row className="flex aligned spaced">
                                                    <MdVisibility size={12} />

                                                    <h4>{element.views}</h4>
                                                </Row>
                                            </Column>

                                            <span />
                                            {element.visibility === 1 ? (
                                                <MdCheckCircle
                                                    color={"#29ff7e"}
                                                />
                                            ) : (
                                                <MdRemoveCircle color={"red"} />
                                            )}
                                        </Row>
                                    </Item>
                                )
                            })}
                        </List>
                    </Foreground>

                    <Foreground width="100%">
                        {selected ? (
                            <GameEditor
                                game={selected}
                                onSave={EditGame}
                                onRemove={RemoveGame}
                                onOpenModal={setBlur}
                            />
                        ) : (
                            <p style={{ width: 1000 }}>Selecione um Jogo</p>
                        )}
                    </Foreground>
                </div>
            </div>
        </Background>
    )
}

export default Dashboard
