import axios from "axios"

const local = false

const api = axios.create({
    baseURL: local ? "http://192.168.100.6:9091" : "https://api.igamesbr.com/",
    proxy: false,
    timeout: 30000,
})

export default api
