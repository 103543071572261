import api from "../api"

export interface NotificationProps {
    title: string
    subtitle: string
    deep_link: string
    image: string
}

export function NotifyAPI(props: NotificationProps & { debug: boolean }) {
    return api.post("/games/notify-advanced", { ...props })
}
