import styled from "styled-components"

export const Background = styled.div<{ blur: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    background-color: #272727;

    ${(p) => p.blur && "filter: blur(5px);"}

    color: white;

    justify-content: center;
    align-items: center;

    span {
        margin: 5px;
    }

    h2 {
        margin-bottom: 20px;
    }

    select,
    option {
        padding: 5px;

        height: 30px;
        background-color: #111;
        border: none;
        color: white;
    }

    select {
        padding: 5px; /* Ajusta o preenchimento do select */
        font-size: 12px; /* Ajusta o tamanho da fonte do select */
    }

    option {
        height: 40px; /* Define a altura desejada para os options */
    }
`

export const NotifyButton = styled.button`
    margin-top: 10px;
    width: 100%;
    background-color: #29ff7e;

    border: none;
    outline: none;
    height: 25px;
    padding: 5px;
    transition: all 0.2s;
    border-radius: 5px;
    :hover {
        background-color: white;

        border-radius: 25px;
    }
    cursor: pointer;
`

export const CreateButton = styled.button`
    all: unset;
    display: flex;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    height: 30px;
    width: 100%;
    padding-inline: 5px;
    background-color: #29ff7e;
    color: black;
    border-radius: 5px;
    align-items: center;
    justify-content: center;

    transition: all 0.2s;

    :hover {
        background-color: #fff;
        border-radius: 25px;
    }
`

export const Idioma = styled.button<{ selected: boolean }>`
    width: 100px;
    padding: 5px;
    border-radius: 5px;
    outline: 0;
    border: none;

    background-color: ${(p) => p && p.selected && "#29ff7e"};

    cursor: pointer;

    :hover {
        opacity: 0.8;
    }

    :focus {
        outline: 0;
    }
`

export const Delete = styled.div`
    aspect-ratio: 1;
    height: 100%;
    border-radius: 25;
    background-color: "red";
    cursor: pointer;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`

export const Cover = styled.div`
    display: flex;

    position: relative;
    width: 100%;
    background-color: #222;

    border: 1px solid grey;

    img {
        aspect-ratio: 0.65;
        width: 100%;
        border-radius: 5px;
    }

    input {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    border-radius: 5px;
    margin-bottom: 15px;

    cursor: pointer;
`

export const PrintsContainer = styled.div`
    display: flex;
    width: 800px;
    height: 200px;
    margin-top: 10px;
    overflow: hidden;
    white-space: nowrap;
`

export const Scroll = styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 20px;
`

export const Print = styled.img`
    height: 200px;
    aspect-ratio: 1.5;
    object-fit: contain;
    background-color: #111;
    border-radius: 10px;
    margin-right: 10px;
`

export const PrintUpload = styled.div`
    position: relative;
    height: 200px;
    aspect-ratio: 1.5;
    object-fit: contain;
    background-color: #111;
    border-radius: 10px;
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`

export const List = styled.ul`
    flex-basis: 1;
    overflow: hidden;
    overflow-y: auto;
    width: 400px;
    height: 100%;
    /* Ajusta a posição do scrollbar para a esquerda */
    ::-webkit-scrollbar-track {
        background: transparent; /* Cor de fundo do container do scrollbar */
    }

    /* Ajusta a posição do scrollbar para a esquerda */
    ::-webkit-scrollbar-track-piece:start {
        margin-left: 0; /* Margem à esquerda */
        margin-right: 12px; /* Largura do scrollbar */
    }

    /* Ajusta a posição do scrollbar para a esquerda */
    ::-webkit-scrollbar-thumb:vertical {
        margin-right: 0; /* Margem à direita */
    }
`

export const Item = styled.li`
    list-style-type: none;
    display: flex;

    height: 35px;

    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    p {
        width: 100%;
        font-size: 12px;
    }

    h4 {
        font-size: 10px;
    }

    background-color: #222;

    cursor: pointer;
`

export const VerticalContainer = styled(Column)`
    flex: 1;
`

export const Foreground = styled.div<{ width: string }>`
    display: flex;
    flex: 1;
    height: 850px;
    padding: 20px;
    border-radius: 5px;

    flex-direction: column;

    background-color: #1d1d1d;
    margin: 5px;

    width: ${(p) => p && p.width};
`

export const UploadObject = styled.label`
    position: absolute;

    height: 100%;
    width: 100%;

    cursor: pointer;
`

export const UploadInput = styled.input.attrs(() => ({
    type: "file",
    accept: ".jpg, .png, .jpeg",
    multiple: true,
    id: "files",
    // Every <Button /> will now have type="button" as default
}))`
    display: none;
`
