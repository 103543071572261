import Cookies from "js-cookie"

export function GetData<T>(tag: string) {
    try {
        let data = Cookies.get(tag)
        if (!data) return null
        let parsed = JSON.parse(data) as T

        return parsed
    } catch {
        return null
    }
}

export function GetDataBool(tag: string) {
    let data = Cookies.get(tag)
    return data !== null && data === "true"
}

export function SetData<T>(tag: string, value: T) {
    Cookies.set(tag, JSON.stringify(value), { expires: 1 })
}
